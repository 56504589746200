import type { ProviderGetImage } from '@nuxt/image';

const keysMap = {
  format: 'f',
  quality: 'q',
  width: 'w',
  height: 'h'
} as Record<string, string>;

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL } = {}
) => {
  if (!baseURL) {
    // also support runtime config
    baseURL =
      useRuntimeConfig().public.cfUrl || 'https://assets.pfsrealty.com/image';
  }

  const mappedModifiers = Object.keys(modifiers).reduce((acc, key) => {
    if (modifiers[key]) {
      const mappedKey = keysMap[key] || key;
      acc[mappedKey] = modifiers[key];
    }
    return acc;
  }, {} as Record<string, string>);

  const params = new URLSearchParams({
    image: src,
    ...mappedModifiers
  });
  return { url: `${baseURL}?${params.toString()}` };
};
